import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useReducer } from 'react';
import { DatePicker } from '../../screens/analytics/components';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';

export type DateTimeFilterValues = {
  fromDate?: Date | null;
  toDate?: Date | null;
  fromTime?: string;
  toTime?: string;
  fromPrice?: string;
  toPrice?: string;
  isApplied?: boolean;
  order_type?: string;
  retailerDeviceId?: string;
};

type DateTimeFilterObj = DateTimeFilterValues & {
  closeFiltersHandler: () => void;
  applyFiltersHandler: (filters: DateTimeFilterValues) => {};
};

const timeBrackets = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectIcon: {
      marginRight: 15,
      color: '#231F20',
    },
    applyBtn: {
      '&:disabled': {
        background: '#CCCCCC !important',
        color: '#ffffff',
      },
    },
  }),
);

const reducer = (
  state: DateTimeFilterValues,
  action: { type: string; data?: any },
) => {
  let newState = {
    fromDate: state.fromDate,
    toDate: state.toDate,
    fromTime: state.fromTime,
    toTime: state.toTime,
    fromPrice: state.fromPrice,
    toPrice: state.toPrice,
    retailerDeviceId: state.retailerDeviceId,
    isApplied: true,
  };
  switch (action.type) {
    case 'FROM_DATE':
      newState.fromDate = action.data;
      break;
    case 'TO_DATE':
      newState.toDate = action.data;
      break;
    case 'FROM_TIME':
      newState.fromTime = action.data;
      break;
    case 'TO_TIME':
      newState.toTime = action.data;
      break;
    case 'FROM_PRICE':
      newState.fromPrice = action.data;
      break;
    case 'TO_PRICE':
      newState.toPrice = action.data;
      break;
    case 'RETAILER_DEVICE_ID':
      newState.retailerDeviceId = action.data;
      break;
    case 'RESET':
      newState = initialState;
      break;
  }
  return newState;
};

const initialState = {
  fromDate: null,
  toDate: null,
  fromTime: '00:00',
  toTime: '23:00',
  fromPrice: '',
  toPrice: '',
  retailerDeviceId: '',
  isApplied: false,
};

const DateTimeFilter = (props: DateTimeFilterObj) => {
  const classes = useStyles();
  const {
    closeFiltersHandler,
    applyFiltersHandler,
    fromDate,
    toDate,
    fromTime,
    toTime,
    fromPrice,
    toPrice,
    isApplied,
    retailerDeviceId,
  } = props;
  const [filtersData, dispatch] = useReducer(
    reducer,
    isApplied
      ? {
          fromDate,
          toDate,
          fromTime,
          toTime,
          fromPrice,
          toPrice,
          isApplied,
          retailerDeviceId,
        }
      : initialState,
  );

  return (
    <Dialog open={true} fullWidth={true}>
      <DialogContent>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{
              fontSize: 18,
              fontWeight: 700,
            }}
          >
            Filter by date
          </Typography>
          <Button
            color={'secondary'}
            style={{
              fontSize: 12,
              fontWeight: 700,
            }}
            onClick={() => dispatch({ type: 'RESET' })}
          >
            Reset
          </Button>
        </Box>
        <Box
          style={{
            display: 'flex',
            alignSelf: 'center',
            justifyContent: 'space-between',
            marginTop: 10,
          }}
        >
          <div>
            <Typography
              style={{ color: '#8992A3', fontWeight: 700, fontSize: 12 }}
            >
              FROM
            </Typography>
            <DatePicker
              isFromDateTimeFilter={true}
              endDate={filtersData.toDate}
              date={filtersData.fromDate}
              handleDateChange={(dateValue: Date | null) => {
                if (moment(dateValue).isAfter(filtersData.toDate)) {
                  dispatch({ type: 'FROM_DATE', data: filtersData.toDate });
                } else {
                  dispatch({ type: 'FROM_DATE', data: dateValue });
                }
              }}
              style={{ marginTop: 8, flex: 1 / 2.3 }}
            />
          </div>
          <div>
            <Typography
              style={{ color: '#8992A3', fontWeight: 700, fontSize: 12 }}
            >
              TO
            </Typography>
            <DatePicker
              isFromDateTimeFilter={true}
              startDate={filtersData.fromDate}
              date={filtersData.toDate}
              handleDateChange={(dateValue: Date | null) => {
                if (moment(dateValue).isBefore(filtersData.fromDate)) {
                  dispatch({ type: 'TO_DATE', data: filtersData.fromDate });
                } else {
                  dispatch({ type: 'TO_DATE', data: dateValue });
                }
              }}
              style={{ marginTop: 8, flex: 1 / 2.3 }}
            />
          </div>
        </Box>
        <Box
          style={{
            display: 'flex',
            marginTop: 10,
          }}
        >
          <Typography
            style={{
              fontSize: 18,
              fontWeight: 700,
            }}
          >
            Filter by time
          </Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 10,
          }}
        >
          <Select
            disabled={!filtersData.fromDate}
            value={filtersData.fromTime}
            onChange={({ target: { value } }) => {
              dispatch({ type: 'FROM_TIME', data: value });
            }}
            renderValue={() => filtersData.fromTime}
            inputProps={{ 'aria-label': 'Without label' }}
            style={{ flex: 1 / 2.3 }}
            classes={{ icon: classes.selectIcon }}
          >
            {timeBrackets.map((entry, index) => {
              return (
                <MenuItem value={entry} key={index}>
                  {entry}
                </MenuItem>
              );
            })}
          </Select>
          <span
            style={{
              color: '#8F9BB3',
              fontWeight: 'bold',
            }}
          >
            -
          </span>
          <Select
            disabled={!filtersData.toDate}
            value={filtersData.toTime}
            onChange={({ target: { value } }) => {
              dispatch({ type: 'TO_TIME', data: value });
            }}
            renderValue={() => filtersData.toTime}
            inputProps={{ 'aria-label': 'Without label' }}
            style={{ flex: 1 / 2.3 }}
            classes={{ icon: classes.selectIcon }}
          >
            {timeBrackets.map((entry, index) => {
              return (
                <MenuItem value={entry} key={index}>
                  {entry}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
        <Box
          style={{
            display: 'flex',
            marginTop: 20,
          }}
        >
          <Typography
            style={{
              fontSize: 18,
              fontWeight: 700,
            }}
          >
            Filter by amount
          </Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 10,
          }}
        >
          <TextField
            value={filtersData.fromPrice}
            type="number"
            placeholder="Enter Amount"
            variant="standard"
            style={{ flex: 1 / 2.3 }}
            onChange={({ target: { value } }) => {
              if (/^\d+(\.\d{0,2})?$/.test(value)) {
                dispatch({ type: 'FROM_PRICE', data: value });
              } else if (!value) {
                dispatch({ type: 'FROM_PRICE', data: '' });
              } else if (value.startsWith('-')) {
                dispatch({ type: 'FROM_PRICE', data: value.replace(/-/g, '') });
              }
            }}
            onBlur={event => {
              if (
                filtersData.toPrice &&
                +event.target.value > +filtersData.toPrice
              ) {
                dispatch({ type: 'FROM_PRICE', data: filtersData.toPrice });
              }
              if (!filtersData.fromPrice) {
                event.target.value = '';
              }
            }}
          />
          <span
            style={{
              color: '#8F9BB3',
              fontWeight: 'bold',
            }}
          >
            -
          </span>
          <TextField
            value={filtersData.toPrice}
            type="number"
            placeholder="Enter Amount"
            variant="standard"
            style={{ flex: 1 / 2.3 }}
            onChange={({ target: { value } }) => {
              if (/^\d+(\.\d{0,2})?$/.test(value)) {
                dispatch({ type: 'TO_PRICE', data: value });
              } else if (!value) {
                dispatch({ type: 'TO_PRICE', data: '' });
              } else if (value.startsWith('-')) {
                dispatch({ type: 'TO_PRICE', data: value.replace(/-/g, '') });
              }
            }}
            onBlur={event => {
              if (
                filtersData.fromPrice &&
                +event.target.value < +filtersData.fromPrice
              ) {
                dispatch({ type: 'TO_PRICE', data: filtersData.fromPrice });
              }
              if (!filtersData.toPrice) {
                event.target.value = '';
              }
            }}
          />
        </Box>
        <Box
          style={{
            display: 'flex',
            marginTop: 20,
          }}
        >
          <Typography
            style={{
              fontSize: 18,
              fontWeight: 700,
            }}
          >
            Retailer Device Id
          </Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 10,
          }}
        >
          <TextField
            value={filtersData.retailerDeviceId}
            type="string"
            placeholder="Enter Retailer device id"
            variant="standard"
            style={{ flex: 1 / 2.3 }}
            onChange={({ target: { value } }) => {
              if (!value) {
                dispatch({ type: 'RETAILER_DEVICE_ID', data: '' });
              } else {
                dispatch({ type: 'RETAILER_DEVICE_ID', data: value });
              }
            }}
            onBlur={event => {
              if (!event.target.value) {
                dispatch({ type: 'RETAILER_DEVICE_ID', data: '' });
              } else {
                dispatch({
                  type: 'RETAILER_DEVICE_ID',
                  data: event.target.value,
                });
              }
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: 'center', marginTop: 100, marginBottom: 20 }}
      >
        <Box>
          <Button
            color="primary"
            variant="outlined"
            style={{
              background: '#C7E2FF',
              borderRadius: 4,
              marginRight: 8,
              width: 144,
              height: 40,
            }}
            onClick={closeFiltersHandler}
          >
            Cancel
          </Button>
          <Button
            disabled={
              filtersData.fromDate || filtersData.toDate
                ? !(
                    filtersData.fromDate &&
                    filtersData.toDate &&
                    ((filtersData.fromPrice && filtersData.toPrice) ||
                      (!filtersData.fromPrice && !filtersData.toPrice))
                  )
                : filtersData.fromPrice || filtersData.toPrice
                ? !(filtersData.fromPrice && filtersData.toPrice)
                : !isApplied
            }
            color="primary"
            variant="contained"
            style={{
              background: '#4180E9',
              borderRadius: 4,
              marginRight: 8,
              width: 144,
              height: 40,
            }}
            className={classes.applyBtn}
            onClick={() => {
              applyFiltersHandler(filtersData);
              closeFiltersHandler();
            }}
          >
            Apply
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
export default DateTimeFilter;
