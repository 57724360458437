import React, { useState, useEffect, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Hidden,
  Box,
  Grid,
  Typography,
  CircularProgress,
  Button,
  List,
  ListItem,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  LinearProgress,
} from '@material-ui/core';
import { useIntl, FormattedMessage } from 'react-intl';
import { NavigationTopBar, OrdersCard } from 'src/components';
import { useTheme } from '@material-ui/styles';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { get } from 'lodash';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import AlertIcon from 'src/assets/svgIcons/AlertIcon';
import {
  currencyFormat,
  dateTimeFormat,
  getCurrency,
  verificationReasons,
} from 'src/utils';
import { useSelector } from 'react-redux';
import { verificationActionApi } from 'src/services/verification';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    height: '90vh',
  },
  body: {
    width: '100%',
    height: '100%',
  },

  title: {
    fontSize: 22,
    fontWeight: 'bold',
    dispay: 'flex',
    marginTop: 16,
    textAlign: 'start',
  },

  button: {
    width: '100%',
    backgroundColor: `${theme.palette.warning.main}1A`,
    border: `1px solid ${theme.palette.warning.main}`,
    height: '40px',
    fontWeight: 'bold',
    borderWidth: 1,
    color: theme.palette.warning.main,
    '& .MuiButton-endIcon': {
      position: 'absolute',
      right: 16,
    },
    textTransform: 'none',
  },

  buttonRipple: {
    backgroundColor: `${theme.palette.warning.main}1A`,
  },

  orderSummary: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: 16,
  },

  orderSummaryContent: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 16,
    alignItems: 'flex-start',
  },

  lightGreyText: {
    fontSize: 13,
    color: theme.palette.color_text_grey.main,
  },
  darkText: {
    fontSize: 13,
    color: theme.palette.color_dark_text.main,
  },

  buttonLeft: {
    width: '167px',
    backgroundColor: `${theme.palette.error.main}1A`,
    border: `1px solid ${theme.palette.error.main}`,
    height: '40px',
    fontWeight: 'bold',
    borderWidth: 1,
    color: theme.palette.error.main,
  },

  buttonRight: {
    width: '167px',
    backgroundColor: `${theme.palette.success.main}`,
    color: theme.palette.color_white.main,
    marginLeft: 16,
    height: '40px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: `${theme.palette.success.main}`,
    },
  },

  buttonLeftRipple: {
    backgroundColor: `${theme.palette.error.main}1A`,
  },
  buttonRightRipple: {
    color: `${theme.palette.success.main}`,
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 84,
    backgroundColor: 'white',
  },

  listItem: {
    overflow: 'scroll',
    height: '65%',
  },

  mobileLoaderContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  mobileLoader: {
    width: 200,
    height: 100,
    backgroundColor: '#CCCCCC',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },

  circle: {
    marginRight: 20,
  },
}));

const VerificationDetails = () => {
  const classes = useStyles();
  const intl = useIntl();
  const [cookies] = useCookies();
  const history = useHistory();
  let { items }: any = history?.location?.state;
  const queryClient = useQueryClient();
  const [modalData, setModalData] = useState<object>({});
  const [alertVisible, showAlert] = useState(false);
  const theme: any = useTheme();
  const {
    code,
    id,
    date,
    reason,
    tags,
    store_name,
    items: listItems,
    store_id,
  } = JSON.parse(items);

  let totalPrice = 0;

  listItems.map((e: any) => {
    totalPrice += e.price;
  });

  const { active_stores } = useSelector((state: any) => state.store);
  const active_store = active_stores.filter(
    (store: any) => store.store_id === store_id,
  )[0];
  console.log({ active_store, store_id, active_stores });

  const handleVerificationAction = (payload: any) => {
    return verificationActionApi(get(modalData, 'sessionId', '')).create(
      { v1: true, token: cookies.app_token },
      JSON.stringify(payload),
    );
  };

  const { isLoading: verificationLoading, mutate: verifyAction } = useMutation(
    handleVerificationAction,
    {
      onSuccess: () => {
        showAlert(false);
        history.goBack();
        queryClient.invalidateQueries('VerificationData');
      },
      onError: error => {
        showAlert(false);
        console.log('Error', { error });
      },
    },
  );

  const alertModal = () => {
    return (
      <Dialog open={alertVisible} onClose={() => showAlert(false)}>
        <DialogTitle>{'Verification'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {get(modalData, 'modalText', '')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              showAlert(false);
            }}
            color="primary"
          >
            {intl.formatMessage({ id: 'cancel' }).toUpperCase()}
          </Button>
          <Button
            onClick={() => {
              showAlert(false);
              verifyAction({ action: get(modalData, 'action', '') });
            }}
            color="primary"
            autoFocus
          >
            {intl.formatMessage({ id: 'confirm' }).toUpperCase()}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Paper className={classes.root}>
      <NavigationTopBar
        title={`${intl.formatMessage({ id: 'request' })} #${code}`}
        subtitle={
          date
            ? `${moment(date).format(
                dateTimeFormat(active_store.store_type),
              )} ( UTC ${moment(date).utc().format('HH:mm')} )`
            : ''
        }
        refresh={false}
      />
      <Hidden only={['xs']} implementation="css">
        {verificationLoading && <LinearProgress color="primary" />}
      </Hidden>
      <Box className={classes.body}>
        <Box style={{ backgroundColor: '#f2f6ff', padding: 16 }}>
          <Button
            className={classes.button}
            variant="outlined"
            TouchRippleProps={{
              classes: { root: classes.buttonRipple },
            }}
            endIcon={<AlertIcon />}
          >
            {verificationReasons(reason, tags, intl)}
          </Button>
          <Typography className={classes.title}>
            <FormattedMessage
              id="basketSummary"
              defaultMessage="Basket Summary"
            />
          </Typography>
          <Box className={classes.orderSummary}>
            <Box className={classes.orderSummaryContent}>
              <Typography className={classes.lightGreyText}>
                <FormattedMessage id="total" />
              </Typography>
              <Typography
                className={classes.darkText}
              >{`${new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: currencyFormat(active_store.currency),
              }).format(totalPrice)}`}</Typography>
              {/* <Typography className={classes.darkText}>{`${getCurrency(
                active_store.currency,
              )} ${totalPrice}`}</Typography> */}
            </Box>
            <Box className={classes.orderSummaryContent}>
              <Typography className={classes.lightGreyText}>
                <FormattedMessage id="items" />
              </Typography>
              <Typography className={classes.darkText}>
                {listItems.length}
              </Typography>
            </Box>
            <Box className={classes.orderSummaryContent}>
              <Typography className={classes.lightGreyText}>
                <FormattedMessage id="storeName" />
              </Typography>
              <Typography className={classes.darkText}>{store_name}</Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.listItem}>
          <List>
            {listItems.map((item: any, index: number) => (
              <Box component="div" key={index}>
                <ListItem>
                  <Box
                    padding={1}
                    style={{
                      display: 'flex',
                      flex: 0.75,
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 13,
                        fontWeight: 'bold',
                        color: '#222b45',
                      }}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                  <Box
                    padding={1}
                    style={{
                      display: 'flex',
                      flex: 0.25,
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 13,
                        color: '#8f9bb3',
                      }}
                    >{`x ${item.qty}`}</Typography>
                    <Typography
                      style={{ marginLeft: 10, fontSize: 13, color: '#8f9bb3' }}
                    >{`${new Intl.NumberFormat('en-GB', {
                      style: 'currency',
                      currency: currencyFormat(active_store.currency),
                    }).format(item.price)}`}</Typography>
                    {/* <Typography
                      style={{ marginLeft: 10, fontSize: 13, color: '#8f9bb3' }}
                    >{`${getCurrency(active_store.currency)} ${
                      item.price
                    }`}</Typography> */}
                  </Box>
                </ListItem>
                {<Divider />}
              </Box>
            ))}
          </List>
        </Box>
        <Box
          className={classes.footer}
          borderTop={1}
          borderColor={theme.palette.color_light_grey.main}
        >
          <Button
            className={classes.buttonLeft}
            variant="outlined"
            disabled={verificationLoading}
            TouchRippleProps={{ classes: { root: classes.buttonLeftRipple } }}
            onClick={() => {
              setModalData({
                modalText: intl.formatMessage({ id: 'confirmRejectCheck' }),
                action: 'reject',
                sessionId: id,
              });
              showAlert(true);
            }}
          >
            <FormattedMessage
              id="reject"
              defaultMessage="REJECT"
            ></FormattedMessage>
          </Button>
          <Button
            className={classes.buttonRight}
            variant="contained"
            disabled={verificationLoading}
            TouchRippleProps={{
              classes: { root: classes.buttonRightRipple },
            }}
            onClick={() => {
              setModalData({
                modalText: intl.formatMessage({ id: 'confirmApproveCheck' }),
                action: 'accept',
                sessionId: id,
              });
              showAlert(true);
            }}
          >
            <FormattedMessage
              id="accept"
              defaultMessage="ACCEPT"
            ></FormattedMessage>
          </Button>
        </Box>
      </Box>
      {alertModal()}
      <Hidden only={['sm', 'md', 'lg', 'xl']} implementation="css">
        {verificationLoading && (
          <Box className={classes.mobileLoaderContainer}>
            <Box className={classes.mobileLoader}>
              <CircularProgress
                className={classes.circle}
                size={25}
                thickness={5}
              ></CircularProgress>
              <Typography>Loading...</Typography>
            </Box>
          </Box>
        )}
      </Hidden>
    </Paper>
  );
};

export default memo(VerificationDetails);
