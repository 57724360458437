import React, { useEffect, useState } from 'react';
import {
  Card,
  Box,
  Typography,
  Button,
  Divider,
  useMediaQuery,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { get } from 'lodash';
import {
  currencyFormat,
  dateTimeFormat,
  getCurrency,
  getOrderCount,
  goToOrderDetails,
  orderStatus,
  updateDecimals,
} from 'src/utils';
import { useHistory } from 'react-router-dom';
import PartialRefundedIcon from 'src/assets/svgIcons/PartialRefundedIcon';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '680px',
    },
    height: '155px',
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('xs')]: {
      margin: 10,
    },
  },
  header: {
    display: 'flex',
    flex: 0.5,
    alignItems: 'center',
    marginLeft: 24,
    marginRight: 15,
  },
  body: {
    display: 'flex',
    flex: 0.5,
    alignItems: 'center',
    marginLeft: 24,
    justifyContent: 'space-between',
  },
  order: {
    fontWeight: 'bold',
    fontSize: '18px',
    color: theme.palette.color_dark_text.main,
    alignSelf: 'flex-start',
    marginBottom: 4,
  },
  date: {
    fontSize: '13px',
    fontWeight: 'bold',
    color: theme.palette.color_dark_text.main,
    alignSelf: 'flex-start',
    marginBottom: 4,
  },
  storeName: {
    fontSize: '13px',
    color: theme.palette.color_dark_text.main,
    alignSelf: 'flex-start',
    marginBottom: 4,
  },
  orderDateTimeContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.5,
  },
  button: {
    height: 40,
    fontWeight: 'bold',
    backgroundColor: (props: any) =>
      `${theme.palette[props.color].main}${
        props.variant === 'outlined' ? '33' : ''
      }`,
    border: (props: any) => `solid 1px ${theme.palette[props.color].main}`,
    textTransform: 'none',
    color: (props: any) =>
      props.variant === 'outlined'
        ? theme.palette[props.color].main
        : theme.palette.color_white.main,
    fontSize: 15,
  },
  buttonContainer: {
    display: 'flex',
    flex: 0.5,
    justifyContent: 'flex-end',
  },
  bodyItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: 30,
  },
  item1: {
    fontSize: 13,
    color: theme.palette.color_text_grey.main,
  },
  item2: {
    fontSize: 13,
    color: theme.palette.color_dark_text.main,
  },
}));

const CCOrdersCard = ({ item }: any) => {
  const {
    o_id,
    date,
    order_status,
    cc_order_status,
    discounted_price,
    currency,
    store_id,
    store_name,
    order_id,
    transaction_id_poslog,
    refunded_items = [],
    non_refunded_items = [],
  } = item;
  const customer = `${get(item, 'customer.first_name', '')} ${get(
    item,
    'customer.last_name',
    '',
  )}`;
  const storetype = get(item, 'store_type', '');
  const itemList = [
    {
      value: `${new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: currencyFormat(currency),
      }).format(discounted_price)}`,
      // value: `${getCurrency(currency)} ${updateDecimals(
      //   discounted_price,
      // ).toFixed(2)}`,
      title: <FormattedMessage id="total" defaultMessage="Total" />,
    },
    {
      value: getOrderCount(item),
      title: <FormattedMessage id="items" defaultMessage="Items" />,
    },
    {
      value: customer,
      title: <FormattedMessage id="customer" defaultMessage="Customer" />,
    },
    {
      value: transaction_id_poslog,
      title: (
        <FormattedMessage id="transactionId" defaultMessage="Transaction ID" />
      ),
    },
  ];
  let isPartialRefund =
    get(item, 'non_refunded_items', []).length > 0 &&
    get(item, 'refunded_items', []).length > 0
      ? true
      : false;
  let orderStatusVal =
    order_status === 'refunded' && cc_order_status === 'collected'
      ? 'collected_refunded'
      : cc_order_status;

  const { icon, status, color, variant } = orderStatus(
    orderStatusVal,
    isPartialRefund,
    useMediaQuery('(max-width:600px)'),
  );
  const history = useHistory();

  const classes = useStyles({ color, variant });
  const intl = useIntl();

  return (
    <Card
      className={classes.root}
      variant="outlined"
      onClick={() => {
        goToOrderDetails({
          order_id,
          type: 'click_and_collect',
          from: 'orders',
          history,
        });
      }}
    >
      <Box className={classes.header}>
        <Box className={classes.orderDateTimeContainer}>
          <Typography className={classes.order}>{`${intl.formatMessage({
            id: 'order',
          })} ${o_id}`}</Typography>
          <Typography className={classes.date}>
            {moment(date).format(dateTimeFormat(storetype))} ( UTC{' '}
            {moment(date).utc().format('HH:mm')} )
          </Typography>
          <Typography className={classes.storeName}>{store_name}</Typography>
        </Box>
        <Box className={classes.buttonContainer}>
          <Button endIcon={icon} className={classes.button} variant={variant}>
            {status}
          </Button>
        </Box>
      </Box>
      <Divider light />
      <Box className={classes.body}>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          {itemList.map((item: any, index: number) => (
            <Box className={classes.bodyItem} key={index}>
              <Typography className={classes.item1}>{item.title}</Typography>
              <Typography className={classes.item2}>{item?.value}</Typography>
            </Box>
          ))}
        </Box>
        {refunded_items.length > 0 && non_refunded_items.length !== 0 && (
          <Box style={{ marginRight: 16, alignItems: 'center' }}>
            <Tooltip title={intl.formatMessage({ id: 'partialRefundAlert' })}>
              <IconButton>
                <PartialRefundedIcon color="error" />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default CCOrdersCard;
